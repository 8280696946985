<template>
  <!-- 流程绘制  跳转后端写好的东西 -->
  <div class="processMap page-info-content">
    <iframe :src="`${toUrl}`" style="border:none;width:100%;height:700px;margin-top:32px;" />
  </div>
</template>

<script>
import { baseURL } from '@/systemConfiguration/index'
export default {
  data() {
    return {
      toUrl: ''
    }
  },
  created() {
    const query = this.$route.query
    let frameSrc = ''
    const token = localStorage.getItem('logintoken')
    if (!query.activitetempid) {
      frameSrc = `/flow/newModel?activiteCode=${query.activitecode}&name=${query.activitename
      }&description=${query.activitedesc}&token=${token}`
    } else {
      frameSrc = `/editor?modelId=${query.activitetempid}&key=${query.activitecode
      }&name=${query.activitename}`
    }
    this.toUrl = `${baseURL}${frameSrc}`
  }
}
</script>

<style lang="scss" scoped>
.processMap {
  width: 100%;
  height: 100%;
}
</style>
